import React, { Fragment, Suspense, lazy } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";
import TokenStorage from "./shared/components/token";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const token = TokenStorage.getToken();
    if (token) {
      TokenStorage.validateToken(token)
        .then(result => {
          setIsTokenValid(result);
          setLoading(false);
        })
        .catch(error => {
          console.error('Token validation error:', error);
          setIsTokenValid(false);
          setLoading(false);
        });
    } else {
      setIsTokenValid(false);
      setLoading(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Fragment />}>
            <Routes>
              <Route path="/c/*" element={isTokenValid ? <LoggedInComponent /> : <LoggedOutComponent />} />
              <Route path="*" element={<LoggedOutComponent />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
